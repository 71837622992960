import { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

const GlobalContextProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(true);

  return (
    <GlobalContext.Provider value={{ showLoader, setShowLoader }}>
      {children}
    </GlobalContext.Provider>
  );
};
export default GlobalContextProvider;
