import axios from "axios";

const API_CONFIG = {
  // baseURL: "http://localhost:1337",
  baseURL: process.env.REACT_APP_API_URL,
};

export const http = axios.create({
  baseURL: API_CONFIG.baseURL,
});

export default API_CONFIG;
