import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import { useGlobalData } from "../../dataService.js";
import Loading from "../Loading.jsx";

const Layout = ({ children }) => {
  const { data, loading } = useGlobalData();

  return !loading ? (
    <>
      <Header data={data.global} />
      {children}
      <Footer data={data} />
    </>
  ) : (
    <Loading />
  );
};

export default Layout;
