import React, { useRef, useState } from "react";
import PrimaryButton from "../common/PrimaryButton";
// import logo from "../../assets/images/homepage/s_logo.svg";
import { ChevronDown, Menu, X } from "lucide-react";
import d_governance from "../../assets/images/common/d_governance.svg";
import d_compliance from "../../assets/images/common/d_compliance.svg";
import d_data from "../../assets/images/common/d_data.svg";
import d_matching from "../../assets/images/common/d_matching.svg";
import d_outsourcing from "../../assets/images/common/d_outsourcing.svg";
import API_CONFIG from "../../config";
import { useGlobalContext } from "../../globalContext.js";
import { Link } from "react-router-dom";

const submenuImg = [
  d_matching,
  d_compliance,
  d_governance,
  d_outsourcing,
  d_data,
];

const Header = ({ data }) => {
  const submenuRef = useRef(null);
  const { showLoader } = useGlobalContext();

  const { logo } = data;
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showMobileSubmenu, setshowMobileSubmenu] = useState(false);

  const [activeSublinkImg, setActiveSublinkImg] = useState(0);

  const toggleNavigation = () => {
    if (showMobileNav) {
      document.documentElement.style.overflowY = "visible";
    } else {
      document.documentElement.style.overflowY = "hidden";
    }
    // console.log(showMobileNav);
    setShowMobileNav(!showMobileNav);
  };

  const handleImgHover = (index) => {
    setActiveSublinkImg(index);
  };

  const blurSubmenu = () => {
    const elem = document.activeElement;
    if (elem) elem?.blur();
  };

  return (
    !showLoader && (
      <div className="bg-purple-8 border-b border-solid border-purple-6 shadow-header fixed md:sticky top-0 w-full z-50">
        <div className="navbar container mx-auto content-center justify-between">
          <div className="navbar-start w-full lg:w-2/12 justify-between">
            {logo.data ? (
              <Link to="/">
                <img
                  src={`${API_CONFIG.baseURL}${logo.data.attributes.url}`}
                  alt="Logo"
                  className="w-full"
                  width={logo.data.attributes.width}
                  height={logo.data.attributes.height}
                />
              </Link>
            ) : (
              <Link to="/">
                <p className="b1 text-white">Logo</p>
              </Link>
            )}
            <div className="">
              <button
                className="btn btn-ghost lg:hidden text-white"
                onClick={toggleNavigation}
              >
                {!showMobileNav ? <Menu size={24} /> : <X size={24} />}
              </button>
              <div
                className={`dropdown-content flex border-t border-solid border-purple-6 lg:hidden absolute overflow-y-auto w-full top-[62px] start-0 bg-purple-8 h-screen px-4 py-10 flex-col gap-4 mb-10 transition-all duration-300 ${
                  showMobileNav ? "translate-x-0" : "translate-x-full"
                }`}
              >
                <ul className="flex flex-col gap-4 items-start mb-10">
                  <li className="py-3">
                    <Link
                      to="/product"
                      className="button text-purple-3 hover:text-purple-1"
                      onClick={toggleNavigation}
                    >
                      Software
                    </Link>
                  </li>

                  <li className="py-3">
                    <Link
                      to="/calculator"
                      className="button text-purple-3 hover:text-purple-1"
                      onClick={toggleNavigation}
                    >
                      Calculator
                    </Link>
                  </li>

                  <li className="py-3">
                    <Link
                      to="/science"
                      className="button text-purple-3 hover:text-purple-1"
                      onClick={toggleNavigation}
                    >
                      Science
                    </Link>
                  </li>
                  <li className="self-stretch">
                    <button
                      className="py-3 mb-1 button text-purple-3 hover:text-purple-1 w-full flex justify-between items-center"
                      onClick={() => setshowMobileSubmenu(!showMobileSubmenu)}
                    >
                      <span>Services</span>
                      <ChevronDown
                        size={24}
                        className={`transition-all duration-300 ${
                          showMobileSubmenu ? "rotate-180" : ""
                        }`}
                      />
                    </button>
                    <div
                      className={`grid transition-all duration-300 ease-in-out ${
                        showMobileSubmenu
                          ? "grid-rows-[1fr]"
                          : "grid-rows-[0fr]"
                      }`}
                    >
                      <ul className={`flex flex-col gap-1 overflow-hidden`}>
                        <li className="p-3">
                          <Link
                            to="/services/matching"
                            className="button text-purple-3 hover:text-purple-1"
                            onClick={toggleNavigation}
                          >
                            340B Matching
                          </Link>
                        </li>
                        <li className="p-3">
                          <Link
                            to="/services/governance"
                            className="button text-purple-3 hover:text-purple-1"
                            onClick={toggleNavigation}
                          >
                            340B Governance
                          </Link>
                        </li>
                        <li className="p-3">
                          <Link
                            to="/services/data-science"
                            className="button text-purple-3 hover:text-purple-1"
                            onClick={toggleNavigation}
                          >
                            Data Science
                          </Link>
                        </li>
                        <li className="p-3">
                          <Link
                            to="/services/compliance"
                            className="button text-purple-3 hover:text-purple-1"
                            onClick={toggleNavigation}
                          >
                            340B Compliance Processing
                          </Link>
                        </li>
                        <li className="p-3">
                          <Link
                            to="/services/navigation-outsourcing"
                            className="button text-purple-3 hover:text-purple-1"
                            onClick={toggleNavigation}
                          >
                            Navigation Outsourcing Solutions
                          </Link>
                        </li>
                      </ul>
                    </div>

                    {/* separator */}
                    <div className="h-px bg-purple-6 mt-4"></div>
                  </li>
                  <li className="py-3">
                    <Link
                      to="/case-studies"
                      className="button text-purple-3 hover:text-purple-1"
                      onClick={toggleNavigation}
                    >
                      Case Studies
                    </Link>
                  </li>
                  <li className="py-3">
                    <Link
                      to="/about-us"
                      className="button text-purple-3 hover:text-purple-1"
                      onClick={toggleNavigation}
                    >
                      About Us
                    </Link>
                  </li>
                </ul>

                <PrimaryButton
                  text="Contact us"
                  link="/contact-us"
                  target="_self"
                  classes="justify-center w-full mb-9"
                  iconName="ArrowRight"
                  iconPosition="right"
                  onClick={toggleNavigation}
                />
              </div>
            </div>
          </div>
          <div className="navbar-center justify-center hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              <li>
                <Link
                  to="/product"
                  className="button text-purple-3 hover:text-purple-1"
                >
                  Software
                </Link>
              </li>

              <li>
                <Link
                  to="/calculator"
                  className="button text-purple-3 hover:text-purple-1"
                >
                  Calculator
                </Link>
              </li>

              <li>
                <Link
                  to="/science"
                  className="button text-purple-3 hover:text-purple-1"
                >
                  Science
                </Link>
              </li>
              <li>
                <div className="dropdown" ref={submenuRef}>
                  <div
                    tabIndex={0}
                    role="button"
                    className="button text-purple-3 hover:text-purple-1 flex items-center gap-1"
                  >
                    Services
                    <ChevronDown size={24} />
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content w-[800px] rounded-lg py-12 px-8 border border-solid border-purple-6 bg-purple-8 left-[-16rem] xl:left-[-10rem] 2xl:left-0"
                  >
                    <li className="grid grid-cols-8 justify-between">
                      <img
                        src={submenuImg[activeSublinkImg]}
                        className="col-span-3"
                        alt="service-img"
                      />
                      <div className="col-span-1 seperator w-px col-span-1 w-px bg-purple-6 ml-8"></div>
                      <ul className="submenu-links col-span-4 flex flex-col gap-8 items-start">
                        <li onClick={blurSubmenu}>
                          <Link
                            to="/services/matching"
                            className="p-0 b2 text-purple-2 hover:text-pink-5"
                            onMouseOver={() => handleImgHover(0)}
                            // onMouseOut={() => handleImgHover(0)}
                          >
                            340B Matching
                          </Link>
                        </li>
                        <li onClick={blurSubmenu}>
                          <Link
                            to="/services/compliance"
                            className="p-0 b2 text-purple-2 hover:text-pink-5"
                            onMouseOver={() => handleImgHover(1)}
                            // onMouseOut={() => handleImgHover(0)}
                          >
                            340B Compliance Processing
                          </Link>
                        </li>
                        <li onClick={blurSubmenu}>
                          <Link
                            to="/services/governance"
                            className="p-0 b2 text-purple-2 hover:text-pink-5"
                            onMouseOver={() => handleImgHover(2)}
                            // onMouseOut={() => handleImgHover(0)}
                          >
                            340B Governance
                          </Link>
                        </li>
                        <li onClick={blurSubmenu}>
                          <Link
                            to="/services/navigation-outsourcing"
                            className="p-0 b2 text-purple-2 hover:text-pink-5"
                            onMouseOver={() => handleImgHover(3)}
                            // onMouseOut={() => handleImgHover(0)}
                          >
                            Navigation Outsourcing Solutions
                          </Link>
                        </li>
                        <li onClick={blurSubmenu}>
                          <Link
                            to="/services/data-science"
                            className="p-0 b2 text-purple-2 hover:text-pink-5"
                            onMouseOver={() => handleImgHover(4)}
                            // onMouseOut={() => handleImgHover(0)}
                          >
                            Data Science
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link
                  to="/case-studies"
                  className="button text-purple-3 hover:text-purple-1"
                >
                  Case Studies
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  className="button text-purple-3 hover:text-purple-1"
                >
                  About us
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar-end w-0/12 lg:w-2/12 hidden lg:inline-flex">
            <PrimaryButton
              text="Contact us"
              link="/contact-us"
              target="_self"
              classes="mr-4"
              iconName="ArrowRight"
              iconPosition="right"
            />
          </div>
        </div>
      </div>
    )
  );
};

export default Header;
