import * as Icons from "lucide-react";
import { Link } from "react-router-dom";

const PrimaryButton = ({
  text,
  link,
  target,
  classes,
  iconName,
  iconPosition = "right",
  type = "link",
  ...props
}) => {
  const IconComponent = Icons[iconName];
  return type === "link" ? (
    <Link
      to={link}
      className={`flex items-center py-3 px-6 bg-purple-6 text-white rounded-lg hover:bg-purple-5 active:bg-purple-7 w-max ${classes}`}
      target={target}
      {...props}
    >
      {iconPosition === "left" && IconComponent && (
        <IconComponent className="inline mr-2" />
      )}
      <span>{text}</span>
      {iconPosition === "right" && IconComponent && (
        <IconComponent className="inline ml-2" />
      )}
    </Link>
  ) : (
    <button
      className={`flex items-center py-3 px-6 bg-purple-6 text-white rounded-lg hover:bg-purple-5 active:bg-purple-7 w-max ${classes}`}
      {...props}
    >
      {iconPosition === "left" && IconComponent && (
        <IconComponent className="inline mr-2" />
      )}
      <span>{text}</span>
      {iconPosition === "right" && IconComponent && (
        <IconComponent className="inline ml-2" />
      )}
    </button>
  );
};

export default PrimaryButton;
