import React from "react";

const Loading = () => {
  return (
    <div className="loading-container flex flex-col items-center justify-center min-h-screen">
      {/* <h3 className="text-sorbus-500 mb-6 text-center">
        {" "}
        Unveiling doors to your professional destiny.{" "}
      </h3> */}
      <span className="loader"></span>
    </div>
  );
};

export default Loading;
