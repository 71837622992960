import React from "react";

const NotFound = () => {
  return (
    <section className="container mx-auto h-96 flex items-center justify-center">
      <div>
        <h1 className="mb-10 text-center">404 - Page Not Found</h1>
        <p className="text-center">
          The page you are looking for does not exist.
        </p>
      </div>
    </section>
  );
};

export default NotFound;
